import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LayoutdashboardComponent } from './layout/layout-dashboard/layoutdashboard.component';
import { LayoutloginComponent } from './layout/layout-login/layoutlogin.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { NotificationComponent } from './shared/notification/notification.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PopupnewcoComponent } from './shared/popupnewco/popupnewco.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LoaderComponent } from './shared/loader/loader.component';
import { ExportDataGraphComponent } from './dashboard/components/export-data-graph/export-data-graph.component';




@NgModule({
  declarations: [
    AppComponent,
    LayoutdashboardComponent,
    NavbarComponent,
    LayoutloginComponent,
    FooterComponent,
    NotificationComponent,
    PopupnewcoComponent,
    LoaderComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot()

  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, // allows you to format the date (go from mm/dd/yyyy to dd/mm/yyyy)
    JwtHelperService,
    DatePipe,
    ExportDataGraphComponent,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }],

  bootstrap: [AppComponent]
})
export class AppModule { }
