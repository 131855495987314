import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PeriodService } from 'src/app/core/services/period.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AgenceId } from 'src/app/core/models/agencyId';
import { DropdownAgencyService } from 'src/app/core/services/dropdown-agency.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit{
  @ViewChild('datepicker_debut') datepicker_debut!: ElementRef;
  @ViewChild('datepicker_fin') datepicker_fin!: ElementRef;
  @ViewChild('agencies_choice') agencies_choice!: ElementRef;

  dropdownList: AgenceId[] = [];
  selectedItems: AgenceId[] = [];
  dropdownSettings = {};
  range = 'jour';
  date_debut: Date = new Date();
  date_fin: Date = new Date();
  maxDate: Date = new Date();

  constructor(
    private periodService: PeriodService,
    private authservice: AuthService,
    private datePipe: DatePipe,
    private dropdownAgencyService: DropdownAgencyService,
    private dateAdapter: DateAdapter<Date> //allows you to put the calendar in French
  ) { this.dateAdapter.setLocale('fr'); }

  ngOnInit(): void {
    this.periodService.date_debut = this.date_debut;
    this.periodService.date_fin = this.date_fin;

    this.dropdownList = this.dropdownAgencyService.dropdownList;
    this.selectedItems = [this.dropdownList[0]];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'agencyName',
      textField: "agencyName",
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  }

  updateAgencyService(items: any[]) {
    const list: AgenceId[] = items.map(item => {
      const dropItem = this.dropdownList.find(dropItem => dropItem.agencyName === item.agencyName);
      if (dropItem) {
        return dropItem;
      } else {
        return null;
      }
    }).filter(dropItem => dropItem !== null) as AgenceId[];
  
    this.dropdownAgencyService.updateSelectedAgencies(list);
  }

  onItemSelect(item: any) {
    this.selectedItems.push(item)
    this.updateAgencyService(this.selectedItems);
  }

  onSelectAll(items: any) {
    this.updateAgencyService(items);
  }

  onItemDeSelect() {
    const list: AgenceId[] = [];
    
    this.selectedItems.forEach(selectedItem => {
      const foundItem = this.dropdownList.find(dropItem => dropItem.agencyName === selectedItem.agencyName);
      if (foundItem) {
        list.push(foundItem as AgenceId); // Cast foundItem en AgenceId
      }
    });
    
    this.updateAgencyService(list);
  }

  onAllItemDeSelect() {
    const listvide: any[] = [];
    this.updateAgencyService(listvide);
  }

  changerange(range: string) {
    this.range = range;
  }

  //used to update the start and end dates of a date range
  updateDates(event: MatDatepickerInputEvent<Date>, datepicker: string) {

    if (datepicker === "debut" && event.value) {
      const date_deb_formatted = this.datePipe.transform(event.value, 'yyyy-MM-dd', 'full')?.toString() ?? '';
      this.date_debut = new Date(date_deb_formatted);
    }

    if (datepicker === "fin" && event.value) {
      const date_fin_formatted = this.datePipe.transform(event.value, 'yyyy-MM-dd', 'full')?.toString() ?? '';
      this.date_fin = new Date(date_fin_formatted);
      this.updatePeriodService(this.date_debut, this.date_fin)
    }
  }

  /**
   * Met à jour les dates dans le service PeriodService.
   */
  updatePeriodService(date_debut: Date, date_fin: Date): void {
    this.periodService.changeDates(date_debut, date_fin);
  }

  /**
   * Ajuste les dates de début et de fin à la date début choisie par l'utilisateur.
   */
  setToDay(): void {
    this.date_fin = new Date(this.date_debut);
    this.updatePeriodService(this.date_debut, this.date_fin);
  }

  /**
   * Met la période actuelle dans les talons de sa semaine.
   * Permet d'ajuster la date du début au Lundi de la date début choisie par l'utilisateur
   * et d'ajuster la date de fin au vendredi de la date début choisie par l'utilisateur.
   */
  setToWeek(): void {
    const day = this.date_debut.getDay();
    const diff = this.date_debut.getDate() - day + (day == 0 ? -6 : 1);
    this.date_debut.setDate(diff);
    const temp = this.date_debut;

    this.date_debut = new Date(temp);
    this.date_fin = new Date(this.date_debut);
    this.date_fin.setDate(this.date_fin.getDate() + 4);
    this.updatePeriodService(this.date_debut, this.date_fin);
  }

  /**
   * Met la période actuelle dans les talons de sa semaine.
   * Permet d'ajuster la date du début au premier jour du mois de la date début choisie par l'utilisateur
   * et d'ajuster la date de fin au dernier jour du mois de la date début choisie par l'utilisateur.
   */
  setToMonth(): void {
    const year = this.date_debut.getFullYear();
    const month = this.date_debut.getMonth();

    // Créez le premier jour du mois (le 1er jour)
    const firstDayOfMonth = new Date(year, month, 1);
    this.date_debut = firstDayOfMonth;

    // Créez le dernier jour du mois
    const lastDayOfMonth = new Date(year, month + 1, 0);
    this.date_fin = lastDayOfMonth;
    this.updatePeriodService(this.date_debut, this.date_fin);
  }

  /**
   * Met la période actuelle dans les talons de sa semaine.
   * Permet d'ajuster la date du début au premier jour de l'année de la date début choisie par l'utilisateur
   * et d'ajuster la date de fin au dernier jour de l'année de la date début choisie par l'utilisateur.
   */
  setToYear(): void {
    const year = this.date_debut.getFullYear();

    // Créez le premier jour de l'année (le 1er jour)
    const firstDayOfYear = new Date(year, 0, 1);
    this.date_debut = firstDayOfYear;

    // Créez le dernier jour de l'année
    const lastDayOfYear = new Date(year + 1, 0, 0);
    this.date_fin = lastDayOfYear;
    this.updatePeriodService(this.date_debut, this.date_fin);
  }

  logout() {
    this.authservice.logout();
  }
  
}
