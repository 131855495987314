import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token = "";
  email = "";
  
  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService
    ) {}


  logout(): void {
    // Code pour la déconnexion de l'utilisateur, par exemple :
    // Effacer le jeton d'authentification du localStorage
    localStorage.removeItem('jwtToken');
    this.router.navigate(['/login'],{queryParams:{sessionExpired:true}});
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('jwtToken');
    return !!token;
  }

  hasExpired(token: string): boolean{  
    return this.jwtHelper.isTokenExpired(token);
}
  

  getToken() : string {
    return localStorage.getItem('jwtToken')!;
  }
  
}
