import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, map } from 'rxjs';
import { DeliveryReportPage, DeliveryReportRecap, DeliveryReportShare } from 'src/app/core/models/delivery_report';
import { ScreenshotMailModel } from '../models/screenshot-mail.model';
import { CsvMail } from '../models/csv-mail';
import { AgenceId } from 'src/app/core/models/agencyId';
import { RetardResponseObject, RetardResponseRecap } from '../models/retard_response';
import { LoginModel } from '../models/login';
import { DeliveryReportHistoryResponse } from '../models/responses/delivery_report_history';
import { MapData } from '../models/mapData';
import { environment } from 'src/environments/environment';
import { DelayGraph } from '../models/delay-graph';
import { SearchModel } from '../models/search-model';
import { StatusGraph } from '../models/status-graph';
import { CsvGraph } from '../models/csv-graph';


@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  get(url: string): Observable<any> {
    return this.http.get(url);
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(url, data);
  }

  // ----------- envoi requêtes au kpi_backend 

  sendContact(email: string, message: string): Observable<any> {
    const body = {
      email: email,
      message: message,
    };
    return this.http.post<any>(`${environment.apiUrl}/users/contact`, body);
  }

  login(data: any): Observable<LoginModel> {
    return this.http.post<any>(`${environment.apiUrl}/auth/login`, data);
  }

  ChangePassword(email: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/forgot`, {
      username: email,
    });
  }

  sendNewPassword(newpass: string, token: string): Observable<any> {
    const body = {
      token: token,
      newPassword: newpass,
    };

    const result: Observable<any> = this.http.post<any>(
      `${environment.apiUrl}/users/changepassword`,
      body
    );
    return result;
  }

  checkToken(token: string): Observable<any> {
    const url = `${environment.apiUrl}/users/checktoken`;
    const body = {
      token: token,
    };
    return this.http.post<any>(url, body);
  }

  getCo2(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<number> {
    const url = `${environment.apiUrl}/deliveryreport/getCO2`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
    };
    return this.http.post<any>(url, body, { headers: headers });
  }


  /**
     * this function retrieves a delivery report based on certain criteria
     *
     * @param agencyId array of agency IDs to filter the report
     * @param pageNo page number to retrieve
     * @param start_date start date to filter results
     * @param end_date end date to filter results
     * @param search search term to filter results
     * @param pageSize The size of each page for pagination.
     * @param col column to sort results by
     * @param order_by sort order (ascending or descending)
     * @returns Observable<DeliveryReportPage> - An Observable that will emit an object of type DeliveryReportPage
     */

  /**
  * Fr
 * Récupère le rapport de livraison en fonction des critères de recherche.
 * @param agencyId Un tableau d'identifiants d'agence pour filtrer le rapport.
 * @param pageNo Le numéro de page pour la pagination.
 * @param date_debut La date de début pour filtrer le rapport.
 * @param date_fin La date de fin pour filtrer le rapport.
 * @param search La chaîne de recherche pour un filtrage supplémentaire.
 * @param pageSize La taille de chaque page pour la pagination.
 * @returns Un Observable de DeliveryReportPage avec les résultats filtrés.
 */
  getDeliveryReportBySearch(search_model: SearchModel): Observable<DeliveryReportPage> {
    const url = `${environment.apiUrl}/deliveryreport/getDeliveryReportBySearch`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: search_model.agency_ids,
      date_debut: search_model.date_debut,
      date_fin: search_model.date_fin,
      page_number: search_model.page_no,
      search: search_model.search,
      page_size: search_model.page_size,
      order_by: search_model.order_by,
      column: search_model.col
    };
    return this.http.post<DeliveryReportPage>(url, body, { headers: headers })
  }

  getCountDeliveryReportWithSpecificStatus(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
    status: any,
    department?: string,
    operationType?: 'Expedition' | 'Delivery' | 'Quality'
  ): Observable<number> {
    const url = `${environment.apiUrl}/deliveryreport/getCountSpecificStatus`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      status: status,
      department: department,
      operationType: operationType
    };
    return this.http.post<number>(url, body, { headers: headers });
  }

  getCountOfAllDeliveryReportByStatut(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
    department?: string,
    operationType?: 'Expedition' | 'Delivery' | 'Quality'
  ): Observable<StatusGraph[]> {
    const url = `${environment.apiUrl}/deliveryreport/getCountOfAllDeliveryReportByStatut`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      department: department,
      operationType: operationType
    };
    return this.http.post<StatusGraph[]>(url, body, { headers: headers });
  }

  getHistory(num_dossier: string): Observable<DeliveryReportHistoryResponse> {
    const url = `${environment.apiUrl}/deliveryHistory/getDeliveryHistory`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      num_recep: num_dossier,
    };
    return this.http.post<DeliveryReportHistoryResponse>(url, body, {
      headers: headers,
    });
  }

  /**
  * ENG Retrieves delivery and expedition data by department for the specified agencies and time period.
  * @param agencyId Array of agency IDs.
  * @param date_debut Start date.
  * @param date_fin End date.
  * @returns An Observable of MapData array containing the delivery data.
  */
  /**
  * FR Récupère les données d'expédition et de livraison par département pour les agences spécifiées et la période de temps.
  * @param agencyId Tableau d'identifiants d'agence.
  * @param date_debut Date de début.
  * @param date_fin Date de fin.
  * @returns Un Observable d'un tableau MapData contenant les données d'expédition.
  */
  getDeliveryAndExpeditionByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getDeliveryAndExpeditionCountByDepartment`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      status: ['SASCFM', 'COMCFM', 'LIVCFM', 'PODCFM', 'CHARGE', 'AARCFM', 'ECHCFM', 'RENNJU', 'AARAVA', 'AARMQT', 'SOLANN', 'SANS STATUT', 'MLVCFM'],
    };
    return this.http.post<MapData[]>(url, body, { headers: headers })
  }

  /** ENG
  * Retrieves the top receivers based on department for a given set of agencies and date range.
  * @param agencyId Array of agency IDs.
  * @param date_debut Start date of the date range.
  * @param date_fin End date of the date range.
  * @returns Observable<MapData[]> representing the top receivers by department.
  */
  /** FR
   * Récupère les principaux destinataires par département pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux destinataires par département.
   */
  getTopReceiverByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopReceiverByDepartment`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body, { headers: headers });
  }

  /** ENG
   * Retrieves the top receivers based on france for a given set of agencies and date range.
   * @param agencyId Array of agency IDs.
   * @param date_debut Start date of the date range.
   * @param date_fin End date of the date range.
   * @returns Observable<MapData[]> representing the top receivers by france.
   */
  /** FR
   * Récupère les principaux destinataires de france pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux destinataires de france.
   */
  getTopReceiverByFrance(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopReceiverByFrance`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body, { headers: headers });
  }

  /** ENG
 * Retrieves the top senders based on department for a given set of agencies and date range.
 * @param agencyId Array of agency IDs.
 * @param date_debut Start date of the date range.
 * @param date_fin End date of the date range.
 * @returns Observable<MapData[]> representing the top senders by department.
 */
  /** FR
   * Récupère les principaux expéditeurs par département pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux expéditeurs par département.
   */
  getTopSenderByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopSenderByDepartment`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    }
    return this.http.post<MapData[]>(url, body, { headers: headers });
  }

  /** ENG
* Retrieves the top senders based on france for a given set of agencies and date range.
* @param agencyId Array of agency IDs.
* @param date_debut Start date of the date range.
* @param date_fin End date of the date range.
* @returns Observable<MapData[]> representing the top senders by france.
*/
  /** FR
   * Récupère les principaux expéditeurs de france pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux expéditeurs de france.
   */
  getTopSenderByFrance(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopSenderByFrance`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body, { headers: headers });
  }

  /** ENG
 * Retrieves the top statuses based on department for a given set of agencies and date range.
 * @param agencyId Array of agency IDs.
 * @param date_debut Start date of the date range.
 * @param date_fin End date of the date range.
 * @returns Observable<MapData[]> representing the top statuses by department.
 */
  /** FR
   * Récupère les principaux statuts par département pour un ensemble donné d'agences et une plage de dates.
   * @param agencyId Tableau d'identifiants d'agence.
   * @param date_debut Date de début de la plage de dates.
   * @param date_fin Date de fin de la plage de dates.
   * @returns Observable<MapData[]> représentant les principaux statuts par département.
   */
  getTopStatusByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopStatusByDepartment`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body, { headers: headers });
  }

  /** ENG
* Retrieves the top statuses based on france for a given set of agencies and date range.
* @param agencyId Array of agency IDs.
* @param date_debut Start date of the date range.
* @param date_fin End date of the date range.
* @returns Observable<MapData[]> representing the top statuses by france
/** FR
 * Récupère les principaux statuts de france pour un ensemble donné d'agences et une plage de dates.
 * @param agencyId Tableau d'identifiants d'agence.
 * @param date_debut Date de début de la plage de dates.
 * @param date_fin Date de fin de la plage de dates.
 * @returns Observable<MapData[]> représentant les principaux statuts de france.
 */
  getTopStatusByFrance(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getTopStatusByFrance`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin
    };
    return this.http.post<MapData[]>(url, body, { headers: headers });
  }

  /** ENG
  * Retrieves the count of deliveries and expeditions abroad for a given list of agencies and date range.
  * @param agencyId List of agency identifiers
  * @param date_debut Start date of the period
  * @param date_fin End date of the period
  * @returns Observable<MapData[]> An Observable containing geographical map data
  */
  /** FR
  * Récupère le nombre de livraisons et d'expéditions à l'étranger pour une liste d'agences et une plage de dates données.
  * @param agencyId Liste des identifiants d'agence
  * @param date_debut Date de début de la période
  * @param date_fin Date de fin de la période
  * @returns Observable<MapData[]> Un Observable contenant les données de la carte géographique
  */
  getCountDeliveryAndExpeditionAbroad(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getDeliveryAndExpeditionCountAbroad`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
    };
    return this.http.post<MapData[]>(url, body, { headers: headers })
  }


  /** ENG
  * Fetches retard (delay) data for a given period and list of agencies.
  * 
  * @param agencyId An array of agency IDs for which retard data is requested.
  * @param date_debut The start date of the period in string format (e.g., "YYYY-MM-DD").
  * @param date_fin The end date of the period in string format (e.g., "YYYY-MM-DD").
  * @returns An Observable emitting an array of RetardResponseObject, representing retard data.
  */
  /** FR
  * Récupère les données de retard pour une période donnée et une liste d'agences.
  * 
  * @param agencyId Un tableau d'identifiants d'agence pour lesquels les données de retard sont demandées.
  * @param date_debut La date de début de la période au format chaîne de caractères (par exemple, "AAAA-MM-JJ").
  * @param date_fin La date de fin de la période au format chaîne de caractères (par exemple, "AAAA-MM-JJ").
  * @returns Un Observable émettant un tableau de RetardResponseObject, représentant les données de retard.
  */
  getDelayReport(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<RetardResponseObject[]> {

    // Construct the URL for the API endpoint
    // Construit l'URL de l'endpoint de l'API
    const url = `${environment.apiUrl}/deliveryreport/getDelayReport`;

    // Retrieve the authentication token
    // Récupère le jeton d'authentification
    const token = this.authService.getToken();

    // Set headers for the HTTP request, including the authorization token
    // Définit les en-têtes pour la requête HTTP, incluant le jeton d'authentification
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    // Construct the request body with start and end dates, and agency IDs
    // Construit le corps de la requête avec les dates de début et de fin, et les identifiants d'agence
    const body = {
      date_debut: date_debut,
      date_fin: date_fin,
      agencies: agencyId,
    };

    // Send an HTTP POST request to the API endpoint with the request body and headers
    // Envoie une requête HTTP POST à l'endpoint de l'API avec le corps de la requête et les en-têtes
    return this.http.post<RetardResponseObject[]>(url, body, {
      headers: headers,
    });
  }


  getDelayGraph(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string
  ): Observable<DelayGraph[]> {
    const url = `${environment.apiUrl}/deliveryreport/getDelayGraph`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
    };
    return this.http.post<DelayGraph[]>(url, body, { headers: headers });
  }

  getStatusCountByDepartment(
    agencyId: AgenceId[],
    date_debut: string,
    date_fin: string,
    status: any
  ): Observable<MapData[]> {
    const url = `${environment.apiUrl}/deliveryreport/getStatusCountByDepartment`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      agencies: agencyId,
      date_debut: date_debut,
      date_fin: date_fin,
      status: status
    };
    return this.http.post<MapData[]>(url, body, { headers: headers });
  }


  // ----------- envoi requêtes au mailer_service

  sendDeliveryReportMail(delivery_report_mail: DeliveryReportRecap) {
    const url = `${environment.mailApi}/sendDeliveryReportMail`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      recipient: delivery_report_mail.recipient,
      subject: delivery_report_mail.subject,
      comments: delivery_report_mail.comments,
      dataMailDelivery: delivery_report_mail.dataMailDelivery,
      sender: delivery_report_mail.sender,
      date_debut: delivery_report_mail.date_debut,
      date_fin: delivery_report_mail.date_fin,
    };
    return this.http.post<string>(url, body, { headers: headers });
  }

  sendDelayReportMail(delay_report_mail: RetardResponseRecap) {
    const url = `${environment.mailApi}/sendDelayReportMail`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      recipient: delay_report_mail.recipient,
      subject: delay_report_mail.subject,
      comments: delay_report_mail.comments,
      dataMailDelay: delay_report_mail.dataMailDelay,
      sender: delay_report_mail.sender,
      date_debut: delay_report_mail.date_debut,
      date_fin: delay_report_mail.date_fin,
    };
    return this.http.post<string>(url, body, { headers: headers });
  }

  sendScreenshotMail(screenshot_mail: ScreenshotMailModel): Observable<string> {
    const url = `${environment.mailApi}/sendScreenshot`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      recipient: screenshot_mail.recipient,
      subject: screenshot_mail.subject,
      comments: screenshot_mail.comments,
      screenshot: screenshot_mail.screenshot,
      sender: screenshot_mail.sender,
      date_debut: screenshot_mail.date_debut,
      date_fin: screenshot_mail.date_fin,
      title: screenshot_mail.title,
    };
    return this.http.post<string>(url, body, { headers: headers });
  }

  sendCSVMail(csv_mail: CsvMail): Observable<string> {
    const url = `${environment.mailApi}/sendCSV`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      recipient: csv_mail.recipient,
      subject: csv_mail.subject,
      comments: csv_mail.comments,
      csv: csv_mail.csv,
      sender: csv_mail.sender,
      type: csv_mail.type,
      date_debut: csv_mail.date_debut,
      date_fin: csv_mail.date_fin,
      title: csv_mail.title,
      token: token
    };
    return this.http.post<string>(url, body, { headers: headers });
  }

  // ----------- envoi requêtes au csv_service

  sendCSVFile(formData: FormData): Observable<any> {
    const url = `${environment.csvApi}/createDeliveryReportFromCSV`;
    return this.http.post<any>(url, formData)
  }

  /** FR
   * Partager le rapport de livraison en générant et téléchargeant un fichier CSV.
   * @param delivery_report_share Les données du rapport de livraison à partager.
   * @returns Un observable indiquant le succès ou l'échec de l'opération.
   */
  /** ENG
   * Share delivery report by generating and downloading CSV file.
   * @param delivery_report_share The delivery report data to be shared.
   * @returns An observable indicating the success or failure of the operation. 
   */
  shareDeliveryReport(delivery_report_share: DeliveryReportShare) {
    const url = `${environment.csvApi}/shareDeliveryReportCSV`;
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      dataShare: delivery_report_share.dataShare,
      date_debut: delivery_report_share.date_debut,
      date_fin: delivery_report_share.date_fin,
    };
    return this.http.post(url, body, {
      headers: headers,
      responseType: 'blob'
    }).pipe(
      map(blob => {
        // On successful response, generate download link and trigger download.
        // En cas de réponse réussie, générer le lien de téléchargement et déclencher le téléchargement.
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        const fileName = `RAPPORTS_DE_LIVRAISON_DU_${delivery_report_share.date_debut}_AU_${delivery_report_share.date_fin}.csv`;
        link.download = fileName;
        link.click();
        // Release the object URL to free resources.
        // Libérer l'URL de l'objet pour libérer des ressources.
        window.URL.revokeObjectURL(downloadUrl);
        // Return true to indicate successful download.
        // Renvoyer true pour indiquer le téléchargement réussi.
        return true;
      }),
    );
  }

  /** ENG
   * Shares a graph CSV via HTTP and returns a sharing status.
   * @param csv_graph The graph CSV data to share.
   * @returns A status indicating when the sharing operation is successfully completed.
   */
  /** FR
  * Partage un fichier CSV de graphique via HTTP et renvoie un statut de partage.
  * @param csv_graph Les données CSV du graphique à partager.
  * @returns Un statut indiquant quand l'opération de partage est terminée avec succès.
  */
  shareGraphCSV(csv_graph: CsvGraph) {

    // Construct the URL for the API endpoint
    // Construit l'URL pour le point de terminaison de l'API
    const url = `${environment.csvApi}/shareGraphCsv`;

    // Get the authentication token
    // Obtient le jeton d'authentification
    const token = this.authService.getToken();

    // Set up the request headers with the authorization token
    // Configure les en-têtes de la requête avec le jeton d'authentification
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const body = {
      csv: csv_graph.csv,
      date_debut: csv_graph.date_debut,
      date_fin: csv_graph.date_fin,
      type: csv_graph.type,
      title: csv_graph.title
    };

    // Send a POST request to the API endpoint with the request body and headers, specifying the response type as a blob
    // Envoie une requête POST au point de terminaison de l'API avec le corps de la requête et les en-têtes,
    // en spécifiant le type de réponse comme un blob
    return this.http.post(url, body, {

      headers: headers,
      responseType: 'blob'
    }).pipe(
      map(blob => {
        // On successful response, generate download link and trigger download.
        // En cas de réponse réussie, générer le lien de téléchargement et déclencher le téléchargement.
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        // Set the href attribute of the <a> element to the download URL
        // Définit l'attribut href de l'élément <a> sur l'URL de téléchargement
        link.href = downloadUrl;
        const fileName = `${csv_graph.title}_du_${csv_graph.date_debut}_au_${csv_graph.date_fin}.csv`;
        link.download = fileName;
        // Simulate a click on the <a> element to trigger the download
        // Simule un clic sur l'élément <a> pour déclencher le téléchargement
        link.click();
        // Release the object URL to free up memory
        // Libère l'URL d'objet pour libérer de la mémoire
        window.URL.revokeObjectURL(downloadUrl);
        // Return true to indicate that the sharing operation was successful
        // Retourne true pour indiquer que l'opération de partage a réussi
        return true;
      })
    );
  }
}