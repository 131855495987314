import { Component, OnInit } from '@angular/core';
import { InitializationService } from './core/services/initialization.service';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'KPI';

  constructor(
    private initializationService: InitializationService,
    private authService: AuthService
  ){}

  ngOnInit(): void {
    if(this.authService.isAuthenticated()){
      this.initializationService.initServices();
    }
  }
  
}
