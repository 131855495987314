import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class PeriodService {

    date_debut: Date = new Date();
    date_fin: Date = new Date();
    update  = 0;
    public update_counter: BehaviorSubject<number> = new BehaviorSubject<number>(this.update);

    constructor(
        private datePipe: DatePipe
    ){}


    getDateDebut(): string {
        const formattedDate = this.datePipe.transform(this.date_debut, "yyyy-MM-dd");
        return formattedDate !== null ? formattedDate : ''; 
      }

      getDateFin(): string {
        const formattedDate = this.datePipe.transform(this.date_fin, "yyyy-MM-dd");
        return formattedDate !== null ? formattedDate : '';
      }

    setDateDebut(date_debut: Date): void {
        this.date_debut = date_debut;
    }

    setDateFin(date_fin: Date): void {
        this.date_fin = date_fin;
    }

    changeDates(date_debut:Date, date_fin: Date) {
        this.setDateDebut(date_debut);
        this.setDateFin(date_fin);
        this.update++;
        this.update_counter.next(this.update);
    }

    getDateDebutAffichageFR() : string{
        return format(this.date_debut, 'EEEE dd MMMM yyyy',{locale:fr})
    }
    getDateFinAffichageFR() : string{
        return format(this.date_fin, 'EEEE dd MMMM yyyy',{locale:fr})
    }

    formaterDate(inputDate: string): string {
        if(!inputDate){
            return "";
        }
        const date = new Date(inputDate);
        return this.datePipe.transform(date, 'dd/MM/yyyy') ?? '';
    }

    getDateDebutwithFormat(formatDate: string){
        return format(this.date_debut, formatDate , {locale:fr});
    }

    getDateFinwithFormat(formatDate: string){
        return format(this.date_fin, formatDate, {locale:fr});
    }

    formatDate(date: Date): string{
        return format(date, "yyyy-MM-dd", {locale:fr});
    }
}
