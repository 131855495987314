import { Injectable } from "@angular/core";
import { HttpService } from "./http-client.service";
import { ScreenshotMailModel } from "../models/screenshot-mail.model";
import { Observable } from "rxjs";
import { CsvMail } from "../models/csv-mail";
import {  DeliveryReportRecap } from "../models/delivery_report";
import { RetardResponseRecap } from "../models/retard_response";

@Injectable({
    providedIn: 'root'
})
export class MailService {


    constructor(
        private httpService: HttpService
    ){}

    sendScreenshotMail(screenshot_mail: ScreenshotMailModel): Observable<string>{
        return this.httpService.sendScreenshotMail(screenshot_mail);
    }

    /**
     * FR: Fonction de transition avec le http service
     * EN: Transition function with the HTTP service.
     * @param csv_mail 
     * @returns 
     * FR: Json avec code HTTP et message de validation ou d'erreur
     * EN: JSON with HTTP code and validation or error message.
     */
    sendCSVMail(csv_mail: CsvMail): Observable<string>{
        return this.httpService.sendCSVMail(csv_mail);
    }
    
    sendDeliveryReport(delivery_report_mail:DeliveryReportRecap){
        return this.httpService.sendDeliveryReportMail(delivery_report_mail);
    }

    sendDelayReport(delay_report_mail:RetardResponseRecap){
        return this.httpService.sendDelayReportMail(delay_report_mail);
    }

}