import { Injectable } from '@angular/core';
import { AgenceId } from '../models/agencyId';
import * as jwt_decode from 'jwt-decode';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

    private agencies: AgenceId[] = [];

    constructor(
      private authService: AuthService,
      ){}
    
    public init(): void {
      this.agencies = this.getAgenciesFromToken();
      
    }

    public getAgencies() : AgenceId[]{
      return this.agencies;
    }

    public getAgenciesFromToken(){
      const token = this.authService.getToken();
      const payload:any = jwt_decode.jwtDecode(token);
      return payload.agencies;
    }

 

}