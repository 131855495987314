import { Injectable } from "@angular/core";
import { Agence } from "../../core/models/agency";
import { BehaviorSubject, Observable } from 'rxjs';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  private viewmodalSubject = new BehaviorSubject<boolean>(false);
  viewmodal$: Observable<boolean> = this.viewmodalSubject.asObservable();

  getToken(): string {
    return localStorage.getItem("jwtToken") ? localStorage.getItem('jwtToken')! : "undefined";
  }

  getUserConnected(): string {
    return localStorage.getItem("jwtToken") ? localStorage.getItem('jwtToken')! : "undefined";
  }

  getAgencyList(): Array<Agence> {
    let agencies: Array<Agence>;
    const storedData = localStorage.getItem("agencyList");
    if (storedData !== null) {
      agencies = JSON.parse(storedData) as Array<Agence>;
    }
    return agencies!;
  }

  savefirstmodal() {
    localStorage.setItem('alreadyco', 'true');
    const newValue = true;
    this.viewmodalSubject.next(newValue);
  }

  getDecodedUser(): string {
    const token : string | null = localStorage.getItem("jwtToken");
    if(!token) return 'undefined';
    const decoded_user: string | undefined = jwtDecode(token).sub;
    return decoded_user ?? 'undefined';
  }

}
