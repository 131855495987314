import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeliveryReport, DeliveryReportPage } from '../models/delivery_report';
import { HttpService } from 'src/app/core/services/http-client.service';
import { PeriodService } from './period.service';
import { DropdownAgencyService } from './dropdown-agency.service';
import { SearchModel } from '../models/search-model';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class TabService {


  pos_history: any[] = [];
  refresh = false;

  private dataSubject = new BehaviorSubject<any>(null);

  private tabIsOpenSubject = new BehaviorSubject<boolean>(false);
  tabIsOpen$: Observable<boolean> = this.tabIsOpenSubject.asObservable();

  // Permet de suivre la MAJ 
  private curentPageSubject = new BehaviorSubject<number>(0);
  curentPage$: Observable<number> = this.curentPageSubject.asObservable();

  // total Page
  private totalPageSubject = new BehaviorSubject<number>(0);
  totalpages$: Observable<number> = this.totalPageSubject.asObservable();

  private listposSubject = new BehaviorSubject<any[]>([]);
  listpos$: Observable<DeliveryReport[]> = this.listposSubject.asObservable();

  private searchBarSubject = new BehaviorSubject<string>("");
  searchBar$: Observable<string> = this.searchBarSubject.asObservable();

  selectedListSent$ = new BehaviorSubject<number>(0);

  selected_list: DeliveryReport[] = [];

  // variables which allows you to sort the table
  public search = "";
  public col = "num_recep";
  public orderBy = "ASC";
  public pageSize = 10;

  constructor(
    private httpService: HttpService,
    public periodService: PeriodService,
    public loaderService: LoaderService,
    private dropDownAgencyService: DropdownAgencyService
  ) {
  }

  public init(): void {
    this.periodService.update_counter.subscribe(() => {
      this.search = "";
      this.resetPosHistory();
      this.generateTab(0);
    });
    this.dropDownAgencyService.updateAgencies$.subscribe(() => {
      this.search = "";
      this.resetPosHistory();
      this.generateTab(0);
    });
  }

  changePage(changePage: boolean) {
    if (changePage) {
      if (this.curentPageSubject.getValue() < this.totalPageSubject.getValue() - 1) {
        this.curentPageSubject.next(this.curentPageSubject.getValue() + 1)
        this.generateTab(this.curentPageSubject.value)
      }
    }
    else {
      if (this.curentPageSubject.getValue() > 0) {
        this.curentPageSubject.next(this.curentPageSubject.getValue() - 1)
        this.generateTab(this.curentPageSubject.value)
      }
    }
  }


  /**
   * this function generates delivery reports based on the specified page number
   *
   * @param page_no number of the page to retrieve
   */
  generateTab(page_no: number) {

    if (this.pos_history[page_no] != null) {
      this.curentPageSubject.next(page_no);
      this.listposSubject.next(this.pos_history[page_no]);
      return;
    }

    const token: string | null = localStorage.getItem('jwtToken');
    if (token !== null && !this.refresh) {
      this.refresh = true;
      this.loaderService.loading()
      const search_model: SearchModel = {
        agency_ids: this.dropDownAgencyService.selectedAgencies,
        page_no: page_no,
        date_debut: this.periodService.getDateDebut(),
        date_fin: this.periodService.getDateFin(),
        search: this.search,
        page_size: this.pageSize,
        col: this.col,
        order_by: this.orderBy
      }
      this.httpService.getDeliveryReportBySearch(search_model)
        .subscribe((data: DeliveryReportPage) => {
          this.curentPageSubject.next(data.number);
          this.totalPageSubject.next(data.totalPages);
          this.listposSubject.next(data.content);
          this.refresh = false;
          this.loaderService.endLoading();
        });
    }
  }

  /**
   * this function handles changing the sort order of folders based on the specified column.
   * the sort order can be ascending ('ASC') or descending ('DESC')
   *
   * @param col name of the column to sort folders by
   */
  sortFolder(col: string, orderBy: string) {
    this.resetPosHistory();
    this.orderBy = orderBy;
    this.col = col;
    this.generateTab(this.curentPageSubject.value);
  }

  getData(): Observable<any> {
    return this.dataSubject.asObservable();
  }

  getTabOpenSubject(): Observable<boolean> {
    return this.tabIsOpen$;
  }

  toggleopentab() {
    const newValue = true;
    this.tabIsOpenSubject.next(newValue);
  }

  toggleclosetab() {
    const newValue = !this.tabIsOpenSubject.value;
    this.tabIsOpenSubject.next(newValue);
  }

  majsearch(search: string) {
    this.search = search;
    this.searchBarSubject.next(search);
  }

  setPageSizeToDefault() {
    this.pageSize = 10;
  }

  generateTabAfterSearchbarChanging(search: string) {
    this.search = search;
    this.resetAll();
    this.generateTab(0);
  }

  resetSelectedList(): void {
    this.selected_list = [];
    this.selectedListSent$.next(this.selectedListSent$.getValue() + 1);
  }

  resetPosHistory(): void {
    this.pos_history = [];
  }

  loadAllandSelectAll(): Observable<DeliveryReportPage> {

    const search_model: SearchModel = {
      agency_ids: this.dropDownAgencyService.selectedAgencies,
      page_no: 0,
      date_debut: this.periodService.getDateDebut(),
      date_fin: this.periodService.getDateFin(),
      search: this.search,
      page_size: 0,
      col: this.col,
      order_by: this.orderBy
    }

    return this.httpService.getDeliveryReportBySearch(search_model);
  }

  resetAll(): void {
    this.resetPosHistory();
  }
}
