<div class="content">

  <div class="tel logouttel">

    <img src="../../../assets/image/logocioremblanc.png" alt="logo ciorem"/>

    <i (click)="logout()" class="fa-solid fa-arrow-right-from-bracket clickable icon" (keydown.enter)="this.logout()" tabindex="0"></i>

  </div>

  <img src="../../../assets/image/logocioremblanc.png" alt="logo ciorem" class="notel"/>
  

  <div class="partcalendar">
    <div class="calendars">
      <div class="datepickerstyle">
        <mat-date-range-input  [rangePicker]="picker">
          <input  (dateChange)="updateDates($event, 'debut')" matStartDate [ngModel]=" date_debut" #datepicker_debut readonly>
          <input  (dateChange)="updateDates($event, 'fin')" matEndDate [ngModel]=" date_fin" #datepicker_fin readonly>
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </div>



      <div class="rangeselector">
        <div (click)="changerange('jour')" [ngClass]="{'active' : range === 'jour'}" class="range clickable active"
          (click)="this.setToDay()" tabindex="0" (keydown.enter)="this.setToDay()">
          <span>Jour</span>
        </div>
        <div (click)="changerange('semaine')" [ngClass]="{'active' : range === 'semaine'}" class="range clickable"
          (click)="this.setToWeek()" tabindex="0" (keydown.enter)="this.setToWeek()">
          <span>Semaine</span>
        </div>
        <div (click)="changerange('mois')" [ngClass]="{'active' : range === 'mois'}" class="range clickable"
          (click)="this.setToMonth()" tabindex="0" (keydown.enter)="this.setToMonth()">
          <span>Mois</span>
        </div>
        <div (click)="changerange('annee')" [ngClass]="{'active' : range === 'annee'}" class="range clickable"
          (click)="this.setToYear()" tabindex="0" (keydown.enter)="this.setToYear()">
          <span>Année</span>
        </div>
      </div>
    </div>
  </div>

  <ng-multiselect-dropdown [placeholder]="'Choisir une agence'" class="choixagences" [settings]="dropdownSettings"
  [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
  (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect()"
  (onDeSelectAll)="onAllItemDeSelect()">
</ng-multiselect-dropdown>




  <i (click)="logout()" class="fa-solid fa-arrow-right-from-bracket clickable icon notel" (keydown.enter)="this.logout()" tabindex="0"></i>

</div>
