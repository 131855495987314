<div class="content">

    <div class="modal">

        <div class="head">
            <img src="../../../assets/image/logocioremblanc.png" class="ciorem" alt="logo ciorem" />
            <h2>Bienvenue sur KPI (Bêta) ! </h2>
        </div>

        <div class="corps">

            <div class="contenu">

                <p>
                /////////////////////////////////////////////////////////////////////////////////////////////////////<br/>  <br/>
                   <span>Vous êtes sur une version <u>Bêta</u> de KPI , </span>           <br/>
                   <span>Merci d'avance pour la remontée d'éventuels bugs</span>   <br/>
                /////////////////////////////////////////////////////////////////////////////////////////////////////
                </p>

                <div class="multiimg">
                    <div class="section">
                      <p>Choisissez une période :
                        <img src="../../../assets/image/newdatepicker.png" class="pimg" id="dateimg" alt="calendrier"/>
                      </p>
                    </div>
                  
                    <div class="section">
                      <p>Choisissez une ou plusieurs agences :
                        <img src="../../../assets/image/selectagencies.png" class="pimg" id="agenciesimg" alt="selection agences"/>
                      </p>
                    </div>
                </div>
                  

                <p>Découvrez des compteurs selon l'état de vos transports :
                    <img src="../../../assets/image/cardinfo.png" class="pimg" alt="carte d'informations" />
                </p>

                <p>Recherchez un transport précisement pour accéder aux détails :
                    <img src="../../../assets/image/newtab.png" class="pimg" alt="tableau de transport"/>
                </p>

                <p>
                    Obtenez une meilleure visibilité des livraisons et expéditions grâce à notre carte interactive: 
                    <img src="../../../assets/image/map.png" class="pimg" alt="Carte de la France"/>
                </p>
                
                <p>
                    Voyez même la qualité de livraison !
                    <img src="../../../assets/image/map-qualite.png" class="pimg" alt="Carte de la France"/>
                </p>

                <div class="multiimg">   
                    <div class="section">
                        <p>
                            Une légende représentant les départements les plus actifs :
                            <img src="../../../assets/image/legende.png" class="pimg" alt="Légende de la carte"/>
                        </p>
                    </div>  
                    <div class="section">
                        <p>
                            Une légende représentant le taux de qualité de la livraison:
                            <img src="../../../assets/image/legende-qualite.png" class="pimg" alt="Légende de la carte"/>
                        </p>
                    </div> 
                </div>

                <div class="section">
                    <p>
                        Basculer en mode livraison, expédition ou qualité facilement !
                       <img src="../../../assets/image/btn-toggle.png"  class="pimg" alt="bouton toggle"/>
                    </p>
                </div> 

                <div class="multiimg">
                    <p>Passez la souris sur un département : </p>
                    <div class="section">
                        <img src="../../../assets/image/hooverdepartment.png"  class="pimg" alt="informations département"/>
                    </div>
                    <div class="section">
                        <img src="../../../assets/image/hoover-qualite.png" class="pimg" alt="Carte de la France"/>
                    </div>
                </div>

                <div class="multiimg">
                    <p>Sélectionnez le département et voyez plus d'informations : </p>
                    <div class="section">
                        <img src="../../../assets/image/selectdepartment.png"  class="pimg" alt="sélection département"/>
                    </div>
                    <div class="section">
                        <img src="../../../assets/image/card-departement.png" class="pimg" alt="Carte de la France"/>
                    </div>
                </div>
          
                <div class="multiimg">
                    <p>Accédez à une multitude de graphiques pour piloter votre activité :</p>
                    <div class="section">
                        <img src="../../../assets/image/graphdeliveryreportsbystatus.png" class="pimg" alt="graphique rapport de livraisons par statut"/>
                    </div>
                    <div class="section">
                        <img src="../../../assets/image/graphstatusdeliveryreports.png" class="pimg" alt="graphique état des rapports de livraisons"/>
                    </div>    
                </div>

                <div class="multiimg">
                    <p>Voyez également vos retards :</p>
                    <div class="section">
                        <img src="../../../assets/image/graphique-retard.png" class="pimg" alt="graphique retard"/>
                    </div>
                    <div class="section">
                        <img src="../../../assets/image/tableau-retard.png" class="pimg" alt="tableau retard"/>
                    </div>    
                </div>

                <p>Partagez ou exportez les informations de votre choix  sous le format de votre choix :
                    <img src="../../../assets/image/modalpartage.png" class="pimg" alt="modal partage csv"/>
                </p>

                <div class="multiimg">
                    <p> Enfin profitez d'avoir un taux de CO2E total convertissable (GCO2E, KGCO2E, TCO2E) mais surtout à la ligne :</p>
                    <div class="section">
                        <img src="../../../assets/image/co2tt.png" class="pimg" alt="CO2 carte d'informations" />
                    </div>
                    <div class="section">
                        <img src="../../../assets/image/co2ligne.png" class="pimg"  id="co2img" alt="CO2 tableau de transport"/>
                    </div>
                </div>
            
                <div class="footer">
                    <button (click)="save()">Passez à l'application</button>
                </div>

            </div>



        </div>



    </div>

</div>
